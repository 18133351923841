@import "../../styles.scss";
.top-menu {
  display: flex;
  position: sticky;
  top: 0;
  box-shadow: 0 0 30px 0 var(--blue);
  justify-content: center;
  background-color: white;
  z-index: 1000;
  height: fit-content;
  font-size: 21px;
  flex-wrap: wrap;
  @include xs {
    font-size: 18px;
  }
  &__button {
    padding: 1rem;
    @include xs {
      font-size: 13px;
      padding: 0.5rem;
    }
    & > a {
      padding: 1rem;
      cursor: pointer;
      @include xs {
        font-size: 16px;
        padding: 0.5rem;
      }
      &:hover {
        background-color: var(--gold);
        color: white;
      }
    }
  }
}
