
.message-box{
  position:fixed;
  bottom: 0.5rem;
  display: flex;
  left: 4vw;
  width: 90vw;
  border-radius: 0.5rem;
  padding: 1rem;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  &__success{
    background-color: #EEF9F3;
    color: #57C282;
  }
  &__error{
    background-color: #FDECEC;
    color: #F44336;
  }
}