@import "../../styles.scss";
.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem;
    letter-spacing: 0.1rem;
      @include xl {

    padding: 1rem;
  }
  @include lg {
    flex-direction: column !important; ;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
  }
  @include xs {
     
      padding: 3rem 1rem ;
  }
  &--inverse{
    justify-content: flex-end;
    @include lg {
      flex-direction: column-reverse !important;
    }
  }
  &__left-img {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  &__img {
    margin: 1rem;
    height: 150px;
    @include xl {
      height: 100px;
    }
    
  }
  &__text {
    line-height: 2.5rem;
    margin: 1rem;
    font-size: 18px;
    font-weight: bold;
    @include lg {
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    @include xs {
      font-size: 12px;
       line-height: 1.5rem;
    }
    &--inverse {
      text-align: right;
      @include lg {
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
    &__top {
    }
    &__title {
      color: var(--gold);
    }
  }
}
