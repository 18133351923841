@import "../../styles.scss";
.party {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  &__info {
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  &__parallax {
    width: 100%;
    height: 1000px;
    @include lg {
      height: 600px;
    }
  }
  &__directions {
    box-shadow: inset 0px 0px 30px 0px rgba(0, 0, 0, 0.75);
    height: 1000px;
    width: 100%;
    display: flex;
     justify-content: flex-end;
    @include lg {
      height: 600px;

      justify-content: center;
      align-items: center;
    }
    &__mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    &__map {
      width: fit-content;
      margin: 15rem;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
      @include lg {
        margin: 0;
      }
    }
  }
}
