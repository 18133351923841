@import '../../styles.scss';
.rsvp {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 3rem;
  
  & > h3 {
    padding-bottom: 2rem;
    margin-top: 0;
    font-size: 17px;
  }
  &__text{
    text-align: center;
    padding-bottom: 2rem;
     font-family: 'Amiri', sans-serif;
     font-style: italic;
  }
  &__title {
    font-size: 56px;
    font-weight: normal;
    letter-spacing: 0.5em;
    color: var(--gold);
  }
  &__form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 1000px;
    @include lg {
      width: 100%;
    }
    &__button {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
      background-color: var(--gold) !important;
    }
    &__desc {
      font-size: 0.85rem;
      text-align: center;

      padding-top: 0;
      padding-bottom: 2rem;
    }
    &__label {
      text-align: right;
      width: 100%;
    }
    &__container {
      padding: 0.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      width: 100%;
      &--label {
        font-size: 22px;
        font-weight: bold;
        padding: 1rem;
        color: var(--gold);
        padding: 2rem;
      }
    }
  }
}
