@import "../../styles.scss";
.info-double {
  display: flex;
  flex-direction: row-reverse !important;
  justify-content: space-between;
  align-items: center;

  &--inverse {
    flex-direction: row !important;
  }
  &__right {
    background-color: var(--gold2);
    font-family: 'Amiri', sans-serif;
    padding: 4rem;
    font-size: 20px;
    text-transform: none;
    text-align: center;
    height:100%;
    font-style: italic;
    letter-spacing: 0.1rem;
    line-height: 2rem;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
      @include xl {
      padding: 10rem 3rem;
    }
    @include lg {
      padding: 5rem;
    }
     @include xs{
      padding: 3rem 1rem ;
      font-size: 18px;;
         line-height: 1.5rem;
    }
  
 
  }
}
