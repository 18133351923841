@import "../../styles.scss";
.church {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  &__ico {
    height: 200px;
  }
  &__title {
    display: flex;
    text-align: center;
  }
  &__content {
    background-color: white;

    &--right {
      font-size: 24px;
    }
  }
  &__info {
    text-align: center;
  }
  &__top {
    text-align: center;
  }
  &__parallax {
    width: 100%;
    height: 1000px;
    @include lg {
      height: 600px;
    }
  }

  &__directions {
    box-shadow: inset 0px 0px 30px 0px rgba(0, 0, 0, 0.75);
    height: 1000px;
    display: flex;
    @include lg {
      height: 600px;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    @include xs {
    }
    &__mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    &__map {
      width: fit-content;
      margin: 15rem;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
      @include lg {
        margin: 0;
      }
    }
    &__button {
      font-size: 36px;
      font-weight: bold;
      margin-top: 1rem !important;
      width: fit-content;
      margin: auto;
      background-color: var(--gold)!important;
      color: var(--blue)!important;
      font-weight: bold !important;
    }
  }
}
