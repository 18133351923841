@import "../../styles.scss";
.food {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-repeat: no-repeat;
  flex-direction: column;

  &__title {
    color: var(--gold);
    font-size: 56px;
    letter-spacing: 0.5em;
    margin-right: -2rem;
    padding-top: 3rem;

    @include xs {
      font-size: 36px;
    }
    @include xxs {
      font-size: 30px;
    }
  }

  &__meal {
    &--title {
      padding: 1rem;
      padding-top: 3rem;
      font-size: 2.5rem;
      color: var(--blue);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      @include xs {
        font-size: 2rem;
      }
      @include xxs {
        font-size: 1.5rem;
      }
    }

    &--time {
      padding-bottom: 1rem;
      font-size: 1.5rem;
      color: var(--blue);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include xs {
        font-size: 1rem;
      }
      @include xxs {
        font-size: 0.5rem;
      }
    }

    &--content {
      color: var(--blue);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
      padding: 0.5rem;
      text-align: center;
      @include xs {
        font-size: 1rem;
      }
      @include xxs {
        font-size: 0.75rem;
      }
    }

    &--allergens {
      color: var(--blue);
      font-size: 1rem;
      padding-bottom: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include xs {
        font-size: 0.75rem;
      }
      @include xxs {
        font-size: 0.5rem;
      }
    }
    
    &--pig {
      color: var(--blue) !important;
      text-decoration: none;
    }
  }
}
