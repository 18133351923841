.footer{
  height:241px;
  z-index: -100;
 
 //background-color: var(--gold);
//
 color: white;
 display: flex;
 align-items: center;
  justify-content: flex-end;
}