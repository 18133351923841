@import "../../styles.scss";
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
  background-repeat: no-repeat;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__image{
    margin: 1rem 2rem;
    margin-right: 3rem;
  }

}
