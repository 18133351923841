:root {
  color-scheme: only light;
}
.content {
    margin: 1.5rem clamp(0.5rem, calc(50vw - 750px), 100vw);
}
@mixin xs {
    @media (max-width: 576px) {
        @content;
    }
}
@mixin xxs {
    @media (max-width: 376px) {
        @content;
    }
}
@mixin lg {
    @media (max-width: 1200px) {
        @content;
    }
}
@mixin xl {
    @media (max-width: 1400px) {
        @content;
    }
}
.root{
    overflow: hidden;
}
body {
    font-family: 'Aboreto', sans-serif;
    color: #082567;
    margin: 0;
    letter-spacing: 0.2rem;
    font-size: 17px;
      @include xs {
        font-size: 18px;
    }
}
h1 {
    font-size: 56px;
    font-weight: normal;
    letter-spacing: 0.5em;
    margin-right: -0.2rem;
    @include xs {
        font-size: 36px;
    }
      @include xxs {
        font-size: 30px;
    }
}
h2 {
    font-size: 48px;
    font-weight: normal;
    letter-spacing: 0.5em;
    color: var(--gold);
    margin-right: -1.5rem;
        @include xs {
        font-size: 32px;
    }
      @include xxs {
        font-size: 28px;
    }
}

:root {
    --blue: #082567;
    --gold: #e7bf81;
    --gold2: #ff990017;
    --gold3: #ff990055;
}

