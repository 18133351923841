@import "../../styles.scss";
.about {
  &__content {
    min-height: 1000px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 32px;
    color: white;
    font-family: "Alex Brush", sans-serif;
    text-transform: none;
    text-align: right;
    box-shadow: inset 0px 0px 30px 0px rgba(0, 0, 0, 0.75);
    @include lg {
      min-height: 500px;
    }
    @include xs {
      min-height: 300px;
    }
    &__text {
      //background-color: rgba(0,0,0,0.5);
      padding: 1rem;
      border-radius: 16px;
      @include xs {
        font-size: 22px;
        line-height: 1.5rem;
      }
    }
  }
}
.react-parallax-content {
  & div > a {
    text-decoration: none;
    cursor: pointer;
    color: white;
  }
}
