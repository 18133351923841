.dim-loader {
    position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
}
